import { redirect, type LoaderFunction } from "@remix-run/node";
import { getUserSession } from "../auth.server/shared";
import { Page } from "../layout";
import { LoginForm } from "../login";

export const loader: LoaderFunction = async ({ request }) => {
  if (await getUserSession(request)) {
    return redirect("/perks");
  }
  return null;
};

export default function LoginPage() {
  return (
    <Page user={null}>
      <main className="container mx-auto">
        <div className="max-w-prose mx-auto text-content">
          <h3>Log in</h3>
          <p>
            Log in to subscribe to event notifications and view member perks.
          </p>
          <LoginForm />
        </div>
      </main>
    </Page>
  );
}
